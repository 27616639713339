<template>
  <div id="app">
    <excel-editor class="prodTbl" :head="tHead" v-model="product">
    </excel-editor>
  </div>
</template>

<script>
import ExcelEditor from "./components/ExcelEditor.vue";

export default {
  name: "App",
  components: {
    ExcelEditor,
  },
  data() {
    return {
      product: [
        {
          q: "BalanceActiv สามารถใช้ได้ต่อเนื่อง 7-14 วัน",
          ans: "A",
          c1: "ถูก",
          c2: "ผิด",
          c3: null,
          c4: null,
          c5: null,
        },
        {
          q: "ข้อดีของ Neo-Penotran Forte เป็น Vaginal suppository ที่เป็น wax based คือ?",
          ans: "C",
          c1: "ใช้ได้เลย ไม่ต้องจุ่มน้ำ",
          c2: "ไม่เลอะ ไม่ทิ้งคราบขาวตกค้าง",
          c3: "ถูกทุกข้อ",
          c4: null,
          c5: null,
        },
      ],
      tHead: [
        {
          key: "q",
          label: "Question",
          type: "string",
          className: "q",
        },
        {
          key: "ans",
          label: "Correct Answer",
          type: "string",
        },
        {
          key: "c1",
          label: "Choice A",
          type: "string",
        },
        ,
        {
          key: "c2",
          label: "Choice B",
          type: "string",
        },
        ,
        {
          key: "c3",
          label: "Choice C",
          type: "string",
        },
        ,
        {
          key: "c4",
          label: "Choice D",
          type: "string",
        },
        ,
        {
          key: "c5",
          label: "Choice E",
          type: "string",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.q {
  min-width: 300px;
}
</style>
