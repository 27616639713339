<template>
  <span
    contenteditable="true"
    @blur="(e) => handleNumberInput(e)"
    v-html="value"
    ref="numberInput"
    style="text-align: right"
  ></span>
</template>
<script>
export default {
  name: "ExcelEditorNumber",
  props: ["value"],
  methods: {
    handleNumberInput(e) {
      var txt = this.$refs.numberInput.innerText.replace(/[^\d.-]/g, "");
      this.$emit("input", txt * 1);
    },
  },
};
</script>
