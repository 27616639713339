<template>
  <div
    :id="id"
    @mousedown="onMouseDown"
    @contextmenu.prevent="handleContextMenu"
  >
    <div class="row">
      <div class="col-12">
        <input type="text" v-model="q" class="form-control" />
      </div>
    </div>
    <div class="excelEditor">
      <table class="excelEditorTbl" ref="excelTbl">
        <thead>
          <th class="menuBar"></th>
          <th
            v-for="(v, index) in tHead"
            :key="index"
            :class="thClass(v.key)"
            @click="setSort(v.key)"
            @mousedown="
              (e) => {
                clearSelect();
                clearFocus();
              }
            "
          >
            {{ v.label }}
          </th>
        </thead>
        <tbody>
          <tr v-for="(idx, index) in filteredDat" :key="index">
            <td
              class="label"
              style="white-space: nowrap"
              @click="(e) => selRow(e, idx)"
            >
              {{ index + 1 }}
            </td>
            <template v-for="(vv, index2) in tHead">
              <excel-editor-item
                :colSpec="vv"
                :key="index + '|' + index2"
                :td="value[idx][vv.key]"
                :row="idx"
                :col="vv.key"
                :selected="selectedRowCol.includes(`${idx}_${vv.key}`)"
                :focusedCell="focusedCell"
                @cellHasFocus="cellHasFocus"
              >
                <template v-slot:default="slotProps" v-if="!vv.popOut">
                  <slot
                    v-if="!['number', 'string', 'date'].includes(vv.type)"
                    :name="vv.type"
                    :row="slotProps.row"
                    :col="slotProps.col"
                    :id="slotProps.id"
                  >
                  </slot>
                  <slot
                    v-if="vv.type == 'number'"
                    name="number"
                    :row="slotProps.row"
                    :col="slotProps.col"
                    :id="slotProps.id"
                  >
                    <excel-editor-number
                      v-model="value[slotProps.row][slotProps.col]"
                    />
                  </slot>
                  <slot
                    v-if="vv.type == 'string'"
                    name="string"
                    :row="slotProps.row"
                    :col="slotProps.col"
                    :id="slotProps.id"
                  >
                    <excel-editor-string
                      v-model="value[slotProps.row][slotProps.col]"
                    />
                  </slot>
                  <slot
                    v-if="vv.type == 'date'"
                    name="date"
                    :row="slotProps.row"
                    :col="slotProps.col"
                    :id="slotProps.id"
                  >
                    <excel-editor-date
                      v-model="value[slotProps.row][slotProps.col]"
                    />
                  </slot>
                </template>
              </excel-editor-item>
            </template>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <svg
                style="cursor: pointer"
                @click="addRow"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                version="1.1"
                viewBox="0 0 32 32"
              >
                <circle style="fill: #252a35" cx="16" cy="16" r="14" />
                <rect
                  style="fill: #ffffff"
                  width="4"
                  height="20"
                  x="-18"
                  y="6"
                  transform="matrix(0,-1,1,0,0,0)"
                />
                <rect
                  style="fill: #ffffff"
                  width="4"
                  height="20"
                  x="14"
                  y="6"
                />
              </svg>
            </td>
            <td :colspan="tHead.length"></td>
          </tr>
        </tfoot>
      </table>
      <div
        v-if="mouseDown"
        class="vue-drag-select-box"
        :style="selectionBoxStyling"
      ></div>
    </div>
    <info-bubble
      v-if="bubbleInfo.show"
      :info="bubbleInfo"
      :slotNames="bubbleSlotName"
      @close="closeBubble"
    >
      <template v-for="v in bubbleSlotName" v-slot:[v]="slotProps">
        <slot
          v-if="!['number', 'string', 'date'].includes(v)"
          :name="v"
          :row="slotProps.row"
          :col="slotProps.col"
        >
          place holder
        </slot>
        <slot
          v-if="v == 'number'"
          name="number"
          :row="slotProps.row"
          :col="slotProps.col"
          :id="slotProps.id"
        >
          <excel-editor-number v-model="value[slotProps.row][slotProps.col]" />
        </slot>
        <slot
          v-if="v == 'string'"
          name="string"
          :row="slotProps.row"
          :col="slotProps.col"
          :id="slotProps.id"
        >
          <excel-editor-string v-model="value[slotProps.row][slotProps.col]" />
        </slot>
        <slot
          v-if="v == 'date'"
          name="date"
          :row="slotProps.row"
          :col="slotProps.col"
          :id="slotProps.id"
        >
          <excel-editor-date v-model="value[slotProps.row][slotProps.col]" />
        </slot>
      </template>
    </info-bubble>
    <div
      class="context-menu"
      v-if="contextMenu"
      :style="{
        left: contextMenu.left + 'px',
        top: contextMenu.top + 'px',
      }"
    >
      <ul>
        <li
          class="context-menu-item"
          v-for="(v, index) in contextMenu.item"
          :key="index"
          @click="menuAction(v.action, v.param)"
        >
          {{ v.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// var cookie = require("js-cookie");
import ExcelEditorNumber from "./ExcelEditorNumber.vue";
import ExcelEditorString from "./ExcelEditorString.vue";
import ExcelEditorDate from "./ExcelEditorDate.vue";
import _debounce from "lodash.debounce";
import InfoBubble from "../components/InfoBubble.vue";
import ExcelEditorItem from "./ExcelEditorItem.vue";
// Takes an array and returns a copy of the array without duplicates
function uniqueArray(array) {
  const newArray = array.concat();
  for (let i = 0; i < newArray.length; ++i) {
    for (let j = i + 1; j < newArray.length; ++j) {
      if (newArray[i] === newArray[j]) {
        newArray.splice(j--, 1);
      }
    }
  }
  return newArray;
}
export default {
  name: "ExcelEditor",
  props: {
    head: Array,

    value: {
      type: Array,
    },
    selectorClass: {
      type: String,
      default: "item",
    },
    color: {
      type: String,
      default: "rgba(0, 162, 255, .4)",
    },
  },
  components: {
    ExcelEditorItem,
    InfoBubble,
    ExcelEditorNumber,
    ExcelEditorString,
    ExcelEditorDate,
  },
  data() {
    return {
      q: "",
      sortCfg: [],
      bubbleInfo: {
        x: 300,
        y: 300,
        pos: "bottom-right",
        show: false,
        row: "",
        col: "",
      },
      contextMenu: null,
      contextMenuEl: null,
      mouseDown: false,
      concat: false,
      startPoint: null,
      endPoint: null,
      selectedMode: "",
      selectedVal: null,
      selectedRow: [],
      selectedRowCol: [],
      focusedCell: null,
      id: `el${this.uuid()}`,
      sortedRow: [],
    };
  },
  methods: {
    handleNumberInput(v, e) {
      console.log("handleNumberInput", v, e, this.$refs.numberInput.innerText);
      var txt = this.$refs.numberInput[0].innerText.replace(/[^\d.-]/g, "");
      this.value[v.row][v.col] = txt;
    },
    setSort(v) {
      var idx = this.sortCfg.findIndex((vv) => vv.by == v);
      console.log(v, idx);
      if (idx < 0) {
        this.sortCfg = [
          {
            by: v,
            di: -1,
          },
          ...this.sortCfg,
        ].slice(0, 3);

        return;
      }
      var o = this.sortCfg.splice(idx, 1)[0];
      o.di *= -1;
      this.sortCfg = [o, ...this.sortCfg];
      // cookie.set(
      //   `${process.env.VUE_APP_SITE}_${this.tplId}_bSortCfg`,
      //   JSON.stringify(this.sortCfg),
      //   {
      //     expires: 99,
      //   }
      // );
    },
    thClass(key) {
      var idx = this.sortCfg.findIndex((v) => v.by == key);
      if (idx < 0) return "";
      return `o${idx} ${
        this.sortCfg[idx].di == -1
          ? "asc"
          : this.sortCfg[idx].di == 1
          ? "desc"
          : ""
      }`;
    },
    async doSort() {
      while (this.value.length != this.raw.length)
        await new Promise((resolve) => setTimeout(resolve, 10));
      this.sortedRow = this.raw.map((v) => v.idx).sort(this.sortFn);
    },
    sortFn(aIdx, bIdx) {
      var i = 0,
        a = this.value[aIdx],
        b = this.value[bIdx];
      while (i < this.sortCfg.length) {
        var by = this.sortCfg[i].by,
          di = this.sortCfg[i].di;
        var aObj = isNaN(a[by]) ? a[by] : a[by] * 1,
          bObj = isNaN(b[by]) ? b[by] : b[by] * 1;
        if (aObj === undefined) return 1;
        if (bObj === undefined) return -1;
        if (aObj != bObj) return aObj > bObj ? di : -di;

        i++;
      }
      return 0;
    },
    selRow(e, idx) {
      console.log("selRow", e, idx);
      if (this.selectedMode != "row") {
        this.selectedMode = "row";
        this.selectedRow = [idx];
        this.genRowModeSelectedVal();
        return;
      }
      if (e.shiftKey) {
        var lastIdx = this.selectedRow[this.selectedRow.length - 1],
          selectedRow = [...this.selectedRow];
        console.log(lastIdx, idx);

        while (lastIdx != idx) {
          console.log("push", idx);

          selectedRow.push(idx);
          idx += lastIdx > idx ? 1 : -1;
        }
        this.selectedRow = [...new Set(selectedRow)];
        this.genRowModeSelectedVal();
        return;
      }
      if (e.ctrlKey) {
        var rowIdx = this.selectedRow.indexOf(idx);
        if (rowIdx >= 0) {
          this.selectedRow.splice(rowIdx, 1);
        } else {
          this.selectedRow.push(idx);
        }
        this.genRowModeSelectedVal();
        return;
      }
      this.selectedRow = [idx];
      this.genRowModeSelectedVal();
    },
    genRowModeSelectedVal() {
      var selectedRow = [...this.selectedRow].sort((_a, _b) => {
        var a = this.row2idx[_a],
          b = this.row2idx[_b];
        return a == b ? 0 : a > b ? 1 : -1;
      });
      console.log("selectedRow", selectedRow);

      var selVal = [],
        rowCol = [];
      selectedRow.forEach((idx) => {
        var val = [];
        this.tHead.forEach((v) => {
          val.push(v.copyFormatter(this.value[idx][v.key]));
          rowCol.push(`${idx}_${v.key}`);
        });
        selVal.push(val);
      });
      this.selectedVal = selVal;
      this.selectedRowCol = rowCol;
    },
    menuAction(act, param) {
      if (act == "copy") this.handleCopy(param);

      if (act == "paste") this.handlePaste(param);
      if (act == "del") {
        var d = JSON.parse(JSON.stringify(this.value));
        d.splice(param, 1);
        this.$emit("input", d);
      }
      this.contextMenu = null;
    },
    clearSelect() {
      this.selectedVal = null;
      this.selectedRowCol = [];
    },
    clearFocus() {
      this.focusedCell = null;
    },
    closeBubble() {
      this.bubbleInfo.show = false;
      this.clearFocus();
    },
    handleCopy: _debounce(function (param) {
      navigator.clipboard.writeText(
        param
          .map((v) => v.map((vv) => JSON.stringify(vv)).join("\t"))
          .join("\r\n")
      );
    }, 100),
    handlePaste: _debounce(function (e) {
      var el =
        e.constructor.name.toLowerCase() == "clipboardevent"
          ? this.focusedCell
          : e;

      if (!el || !("row" in el)) return;
      console.log("paste", el);

      var row0 = this.row2idx[el.row],
        col0 = this.col2idx[el.col].idx;
      navigator.clipboard.readText().then((text) => {
        var d = text
          .trim()
          .split(/\r?\n/)
          .map((v) =>
            v.split("\t").map((vv) => {
              try {
                return JSON.parse(vv);
              } catch (e) {
                return vv;
              }
            })
          );

        if (!d.length) return;
        var o = this.getRow();
        var dat = JSON.parse(JSON.stringify(this.value));
        d.forEach((v, rowIdx) => {
          var newRow =
            row0 + rowIdx in this.filteredDat
              ? null
              : JSON.parse(JSON.stringify(o));
          v.forEach((vv, colIdx) => {
            var col = this.tHead[col0 + colIdx]?.key;
            if (
              col &&
              row0 + rowIdx in this.filteredDat &&
              this.filteredDat[row0 + rowIdx] in dat
            )
              dat[this.filteredDat[row0 + rowIdx]][col] =
                this.col2idx[col].pasteFormatter(vv);
            if (newRow !== null)
              newRow[col] = this.col2idx[col].pasteFormatter(vv);
          });
          if (newRow !== null) dat.push(newRow);
        });

        this.$emit("input", dat);
      });
    }, 100),
    getRow() {
      return this.tHead.reduce((t, v) => {
        t[v.key] = "default" in v ? v.default : null;
        return t;
      }, {});
    },
    addRow() {
      console.log("addRow");

      var o = this.getRow();
      var v = JSON.parse(JSON.stringify(this.value));
      v.push(o);
      this.$emit("input", v);
    },
    cellHasFocus(v) {
      this.contextMenu = null;
      this.focusedCell = v;
      this.$emit("cellHasFocus", v);
      this.$nextTick((_) => {
        if (this.bubbleSlotName.includes(v.type)) {
          var __ = this.$el.getBoundingClientRect();
          var _ = v.el.getBoundingClientRect();
          this.bubbleInfo.row = v.row;
          this.bubbleInfo.col = v.col;
          this.bubbleInfo.x = _.left + _.width / 2 - __.left;
          this.bubbleInfo.y = _.top + _.height / 2 - __.top;
          this.bubbleInfo.show = true;
        }
        var el = document.querySelector(`#${v.id}`);
        if (!el) return;
        this.clearSelect();
        v.el.focus();
        // return;
        if (v.type == "date") {
          el.click();
        }
        if (v.type == "string") {
          el.focus();
          document.execCommand("selectAll", false, null);
          document.getSelection().collapseToEnd();
        }
      });
    },
    getScroll() {
      // If we're on the server, default to 0,0
      if (typeof document === "undefined") {
        return {
          x: 0,
          y: 0,
        };
      }
      return {
        x:
          this.$el.scrollLeft ||
          document.body.scrollLeft ||
          document.documentElement.scrollLeft,
        y:
          this.$el.scrollTop ||
          document.body.scrollTop ||
          document.documentElement.scrollTop,
      };
    },
    async handleContextMenu(e) {
      const children = this.$el.querySelectorAll(`#${this.id} td:not(.label)`);

      const clientRect = this.$refs.excelTbl.getBoundingClientRect();
      const scroll = this.getScroll();

      var p = {
        left: e.pageX - clientRect.left - scroll.x,
        top: e.pageY - clientRect.top - scroll.y,
      };
      if (children) {
        var el = Array.from(children).filter((item) => {
          return this.isItemIntersectWithPoint(p, item);
        })[0];
        if (!el) return;
        this.contextMenuEl = el;
        var item = [
          {
            label: `Delete Row #${this.row2idx[el.dataset.row * 1] + 1}`,
            action: "del",
            param: el.dataset.row * 1,
          },
        ];

        if (this.selectedVal)
          item.push({
            label: "Copy",
            action: "copy",
            param: JSON.parse(JSON.stringify(this.selectedVal)),
          });
        if (await navigator.clipboard.readText())
          item.push({
            label: "Paste",
            action: "paste",
            param: {
              row: el.dataset.row * 1,
              col: el.dataset.col,
            },
          });
        this.contextMenu = {
          left: e.pageX - scroll.x + 5,
          top: e.pageY - scroll.y + 5,
          item,
        };
      }
    },
    onMouseDown(event) {
      if (event.button === 2) return;
      this.concat = event.shiftKey;
      this.mouseDown = true;
      this.startPoint = {
        x: event.pageX,
        y: event.pageY,
      };
      window.addEventListener("mousemove", this.onMouseMove);
      window.addEventListener("mouseup", this.onMouseUp);
    },
    onMouseMove(event) {
      this.contextMenu = null;
      if (this.mouseDown) {
        this.endPoint = {
          x: event.pageX,
          y: event.pageY,
        };
        const children = this.$el.querySelectorAll(
          `#${this.id} td:not(.label)`
        );
        if (children) {
          var sel = {},
            selRowCol = [],
            minCol = Number.MAX_SAFE_INTEGER,
            minRow = Number.MAX_SAFE_INTEGER,
            maxCol = -1,
            maxRow = -1;
          Array.from(children)
            .filter((item) => {
              return this.isItemSelected(item);
            })
            .forEach((v) => {
              if (!("row" in v.dataset)) return;
              var row = this.row2idx[v.dataset.row * 1],
                col = this.col2idx[v.dataset.col].idx;
              minRow = row < minRow ? row : minRow;
              maxRow = row > maxRow ? row : maxRow;
              minCol = col < minCol ? col : minCol;
              maxCol = col > maxCol ? col : maxCol;
              if (!(row in sel)) sel[row] = {};
              sel[row][col] = this.value[v.dataset.row][v.dataset.col];
              selRowCol.push(`${v.dataset.row}_${v.dataset.col}`);
            });
          if (!Object.keys(sel).length) return;
          var mat = new Array(maxRow - minRow + 1)
            .fill(0)
            .map(() => new Array(maxCol - minCol + 1));
          for (var i = minRow; i <= maxRow; i++)
            for (var j = minCol; j <= maxCol; j++)
              mat[i - minRow][j - minCol] = this.tHead[j].copyFormatter(
                sel[i][j]
              );
          this.selectedVal = mat;
          this.selectedRowCol = selRowCol;
          this.selectedMode = "";
        }
      }
    },
    onMouseUp(event) {
      window.removeEventListener("mousemove", this.onMouseMove);
      window.removeEventListener("mouseup", this.onMouseUp);
      this.mouseDown = false;
      this.concat = false;
      this.startPoint = null;
      this.endPoint = null;
    },
    isItemSelected(el) {
      const boxA = this.selectionBox;
      const boxB = {
        top: el.offsetTop,
        left: el.offsetLeft,
        width: el.clientWidth,
        height: el.clientHeight,
      };

      return !!(
        boxA.left <= boxB.left + boxB.width &&
        boxA.left + boxA.width >= boxB.left &&
        boxA.top <= boxB.top + boxB.height &&
        boxA.top + boxA.height >= boxB.top
      );
    },
    isItemIntersectWithPoint(p, el) {
      const boxA = { top: p.top, left: p.left, width: 1, height: 1 };
      const boxB = {
        top: el.offsetTop,
        left: el.offsetLeft,
        width: el.clientWidth,
        height: el.clientHeight,
      };
      return !!(
        boxA.left <= boxB.left + boxB.width &&
        boxA.left + boxA.width >= boxB.left &&
        boxA.top <= boxB.top + boxB.height &&
        boxA.top + boxA.height >= boxB.top
      );
    },
    cellMouseOver(e) {
      const cell = e.target;
      if (!cell.classList.contains("error")) return;
      if (this.tipTimeout) clearTimeout(this.tipTimeout);
      if ((this.tip = this.errmsg[cell.getAttribute("id")]) === "") return;
      const rect = cell.getBoundingClientRect();
      this.$refs.tooltip.style.top = rect.top - 14 + "px";
      this.$refs.tooltip.style.left = rect.right + 8 + "px";
      cell.addEventListener("mouseout", this.cellMouseOut);
    },
    uuid(a) {
      return a
        ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
        : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this.uuid);
    },
    defaultNumberFormatter(v) {
      return v == null || v == ""
        ? ""
        : (v * 1).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
    },
    defaultNumberPasteFormatter(v) {
      return isNaN(v) ? null : v * 1;
    },
    defaultDateFormatter(v) {
      if (!v) return "";
      var d = new Date(v);
      if (isNaN(d.valueOf())) return "";
      return `${("0" + d.getDate()).slice(-2)} ${
        [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ][d.getMonth()]
      } ${d.getFullYear()}`;
    },
    defaultDateCopyFormatter(v) {
      if (!v) return "";
      var d = new Date(v);
      if (isNaN(d.valueOf())) return "";
      return `${d.toISOString()}`;
    },
    defaultDatePasteFormatter(v) {
      if (!v) return null;
      var d = new Date(v);
      return isNaN(d.valueOf()) ? null : d;
    },
  },
  computed: {
    row2idx() {
      return this.filteredDat.reduce((t, v, idx) => {
        t[v] = idx;
        return t;
      }, {});
    },
    filteredDat() {
      var qList = this.q
        .toLowerCase()
        .split(" ")
        .filter((v) => v);
      if (!qList.length) return this.sortedRow;
      var ret = [...this.raw];
      for (var q of qList) {
        ret = ret.filter((v) => v._q.indexOf(q) >= 0);
      }
      var filteredIds = ret.map((v) => v.idx);
      return this.sortedRow.filter((v) => filteredIds.includes(v));
    },
    raw() {
      console.log("cal raw");

      var d = [],
        th = this.tHead.map((v) => v.key);
      for (var idx in this.value) {
        var o = this.value[idx];
        d.push({
          idx: idx * 1,
          _q: th
            .reduce((t, k) => {
              if (k in o && o[k] !== null) t.push(`${o[k]}`.toLowerCase());
              return t;
            }, [])
            .join("|"),
        });
      }
      return d;
    },
    col2idx() {
      return this.tHead.reduce((t, v, idx) => {
        t[v.key] = Object.assign({ idx }, v);
        return t;
      }, {});
    },
    bubbleSlotName() {
      return this.tHead.filter((v) => v.popOut).map((v) => v.type);
    },
    tHead() {
      var ret = [];
      this.head.forEach((v) => {
        var o = {};
        if (typeof v == "string") {
          Object.assign(o, { key: v, label: v, type: "string", popOut: false });
        }
        Object.assign(o, {
          key: v.key || v.label,
          label: v.label || v.key,
          type: v.type || "string",
          popOut: !!(v.popOut || false),
          style: v.style,
          className: v.className,
          readOnly: !!(v.readOnly || false),
          alwaysShow: !!(v.alwaysShow || false),
          default: v.default || null,
        });
        if (o.type == "number") {
          o.formatter = this.defaultNumberFormatter;
          o.pasteFormatter = this.defaultNumberPasteFormatter;
        }
        if (o.type == "date") {
          console.log("this is date");

          o.formatter = this.defaultDateFormatter;
          o.copyFormatter = this.defaultDateCopyFormatter;
          o.pasteFormatter = this.defaultDatePasteFormatter;
        }
        o.formatter = v.formatter ?? o.formatter ?? ((_) => _);
        o.copyFormatter =
          v.copyFormatter ?? o.copyFormatter ?? ((_) => _ ?? "");
        o.pasteFormatter =
          v.pasteFormatter ??
          o.pasteFormatter ??
          ((_) => (_ === "" ? null : _));
        ret.push(o);
      });
      return ret;
    },
    selectionBox() {
      // Only set styling when necessary
      if (!this.mouseDown || !this.startPoint || !this.endPoint) return {};

      const tblRect = this.$refs.excelTbl.getBoundingClientRect();

      const scroll = this.getScroll();

      // Calculate position and dimensions of the selection box
      const left =
        Math.min(this.startPoint.x, this.endPoint.x) - tblRect.x - scroll.x;
      const top =
        Math.min(this.startPoint.y, this.endPoint.y) - tblRect.y - scroll.y;
      const width = Math.abs(this.startPoint.x - this.endPoint.x);
      const height = Math.abs(this.startPoint.y - this.endPoint.y);

      // Return the styles to be applied
      return {
        left,
        top,
        width,
        height,
      };
    },
    selectionBoxStyling() {
      // Only set styling when necessary
      if (!this.mouseDown || !this.startPoint || !this.endPoint) {
        return { background: this.color };
      }
      const { left, top, width, height } = this.selectionBox;

      // Return the styles to be applied
      return {
        background: this.color,
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`,
      };
    },
  },
  watch: {
    value() {
      this.doSort();
    },
    sortCfg() {
      this.doSort();
    },
    selectedVal(n, o) {
      this.$emit("change", n);
    },
  },
  mounted() {
    this.doSort();
  },
  beforeDestroy() {
    // Remove event listeners
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
    return;
    const children = this.$children.length ? this.$children : this.$el.children;

    for (var child of children) {
      child.$off("click");
    }
  },
};
</script>

<style lang="scss">
.excelEditor {
  position: relative;
  table-layout: fixed;
  width: 0px;
  border: 0;
  border-collapse: separate;
  border-spacing: 0;

  // z-index: -1;
  .delIcon {
    position: relative;
    top: -3px;
    cursor: pointer;
  }
  .excelEditorTbl {
    border: 1px solid lightgray;
  }
  th,
  tfoot td {
    white-space: nowrap;
    user-select: none;
    background: #e9ecef;
    width: 40px;
    position: sticky;
    left: 0;
    top: auto;
    text-overflow: inherit !important;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    z-index: 5;
    padding: 0.2rem 0.3rem;
    font-weight: 400;
    top: 0;
    height: 29px;
    position: sticky;
    z-index: 5;
  }
  th:first-child {
    padding: 0 15px;
  }
  th,
  td {
    padding: 0.2rem 0.3rem;
    user-select: none;
    border-bottom: 1px solid lightgray;
    &:not(:last-child) {
      border-right: 1px solid lightgray;
    }
  }
  td {
    height: 28px;
    &.label {
      background: #e9ecef;
      text-align: center;
    }
    &.sel {
      background: rgba(0, 128, 128, 0.15);
    }
    &.focus {
      outline: 1px solid greenyellow;
    }
    &.error {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAAlwSFlzAAAXEgAAFxIBZ5/SUgAAAGZJREFUOBGlzjsOgDAMA9CwcQSO0PtP3K64Qyugv8S2ZMXTUw5DstmFk8qWAuhEbzSzbQ+oWIPKULAPpGAdxGJDiMGmUBRbQhFsC3kxF+TB3NAOC0ErLAzNMAoaYTT0xyTojclQxR5H5B1HhuS+WAAAAABJRU5ErkJggg==") !important;
      background-repeat: no-repeat !important;
      background-size: 8px 8px !important;
      background-position: right 0px top 0px !important;
    }
    span {
      width: 100%;
      height: 100%;
      display: inline-block;
      white-space: pre-wrap;
      &[contenteditable]:focus {
        outline: 1px solid transparent;
      }
    }
    .vdp-datepicker input {
      background: transparent;
      border: 0 none;
      color: inherit;
      &:focus {
        outline: 1px solid transparent;
      }
    }
  }
  th {
    cursor: pointer;
    &.menuBar {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAJZJREFUWIXt17ENwjAQRuHPoWIGJoAJ2IKaBUGiyxbZIpmCVHEoCBISqc9I8ZMsXfcs6+7Xma2TcMAZTbA7o4Mec6HTp6UoRvSzr14gF/TnHY44eTdkqByPYGel8kvCFRfxUzCjTXhiHyz/MNYobjAW9I/fTVhiH2iDnZXKOjdM4hfSCfe0FKUCKdcobjAU9A9/8TXbNi9oOlZilv4K0gAAAABJRU5ErkJggg==");
      background-repeat: no-repeat !important;
      background-size: 18px 18px !important;
      background-position: center center !important;
    }
    &.o1:after {
      opacity: 0.6;
    }
    &.o2:after {
      opacity: 0.3;
    }
    &.desc,
    &.asc {
      position: relative;
      padding-right: 25px;
      &:after {
        content: "";
        right: 0px;
        top: 7px;
        width: 18px;
        height: 18px;
        display: block;
        position: absolute;
        background-repeat: no-repeat !important;
        background-position: right center !important;
        background-size: 18px 18px !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAA7DgAAOw4AXEryjgAAAAHdElNRQfmAhUPLyOq6ajQAAABFUlEQVRIx9XUMU7CABjF8V8tJAQnJwYMB3Aw8QbGK3ACBxOvYEx0cMDoWbyBuxgnN3c0ygEMRCp1QFIqDbRVib61/d773v9Ly79XUOjtigb6orJxTV1dzbRndk5FjECUygs1ES4zaLiy+WnwqO1p0VJr34WYtUFfO1WhsEG0eOlfrJDQn+rrFZYYJPQTg9VeIaGfrpDboBD9H60wTz9dYekV5uknBqu5wjz9dAXWbbn3NvOkatuD13whoSMvDgVaenpaAgeenQqzP6asohs63l2LEdp3oT75HYY5xmN3xvbsGtsR4FjdpY5RflY1JwaGRkaGBs7UiuKeWMTicuOzW6TG8zCYKnIrcuPcsEz+RFXV8sN/VB9JxVU3bgmjGgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wMi0yMVQxNTo0NzozNCswMDowMITJsbsAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDItMjFUMTU6NDc6MzQrMDA6MDD1lAkHAAAAIHRFWHRzb2Z0d2FyZQBodHRwczovL2ltYWdlbWFnaWNrLm9yZ7zPHZ0AAAAYdEVYdFRodW1iOjpEb2N1bWVudDo6UGFnZXMAMaf/uy8AAAAYdEVYdFRodW1iOjpJbWFnZTo6SGVpZ2h0ADUxMo+NU4EAAAAXdEVYdFRodW1iOjpJbWFnZTo6V2lkdGgANTEyHHwD3AAAABl0RVh0VGh1bWI6Ok1pbWV0eXBlAGltYWdlL3BuZz+yVk4AAAAXdEVYdFRodW1iOjpNVGltZQAxNjQ1NDU4NDU0P6sjNwAAABJ0RVh0VGh1bWI6OlNpemUAMjg5MEJCGsa/qAAAAEp0RVh0VGh1bWI6OlVSSQBmaWxlOi8vLi91cGxvYWRzLzU2L1VURGM1UzEvMzQwOC9hc2NlbmRpbmdfc29ydF9pY29uXzIxNzAyMy5wbmfQLbMoAAAAAElFTkSuQmCC");
      }
    }
    &.desc:after {
      transform: rotateX(180deg);
    }
  }
}
.context-menu {
  position: fixed;
  z-index: 999;
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 #eee;

  &:focus {
    outline: none;
  }

  ul {
    padding: 0px;
    margin: 0px;
  }
  .context-menu-item {
    display: block;
    position: relative;
    padding: 2px 2px;
    background: rgb(161, 161, 161);
    color: #000;
    border-radius: 0;
    text-decoration: none;
    font-size: 13px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding: 8px;

    &:hover,
    &:focus {
      background: lightgray;
      outline: none;
    }
  }
}

.tool-tip {
  display: inline-block;
  position: fixed;
  color: white;
  background-color: red;
  padding: 0.5rem;
  min-height: 1rem;
  max-width: 200px;
  word-wrap: break-word;
  border-radius: 4px;
  z-index: 50;
}
.tool-tip:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid red;
  left: -8px;
  top: 8px;
}
.vue-drag-select-box {
  position: absolute;
  z-index: 99;
}
</style>
