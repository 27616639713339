<template>
  <datepicker
    input-class="inp"
    :value="value"
    @input="(e) => $emit('input', e)"
  />
</template>
<script>
import Datepicker from "vuejs-datepicker";
export default {
  name: "ExcelEditorDate",
  props: ["value"],
  components: { Datepicker },
};
</script>
