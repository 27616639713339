<template>
  <td
    @click="handleClk"
    :data-col="this.col"
    :data-row="this.row"
    :class="[selected ? 'sel' : '', hasFocus ? 'focus' : '']"
  >
    <slot v-if="showSlot" :row="row" :col="col" :id="id"></slot>
    <span
      v-else
      :style="colSpec.style"
      :class="colSpec.className"
      v-html="formattedVal"
    ></span>
  </td>
</template>
<script>
export default {
  name: "ExcelEditorItem",
  props: {
    colSpec: { type: Object },
    td: null,
    focusedCell: Object,
    row: Number,
    col: String,
    selected: Boolean,
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    handleClk(e) {
      if (this.colSpec.readOnly) return;
      if (this.hasFocus) return;
      // console.log("clk", this.row, this.col, e);
      this.id = `el${this.uuid()}`;
      this.$emit("cellHasFocus", {
        row: this.row,
        col: this.col,
        type: this.colSpec.type,
        id: this.id,
        el: this.$el,
      });
    },
    uuid(a) {
      return a
        ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
        : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this.uuid);
    },
  },
  computed: {
    showSlot() {
      return (
        this.colSpec.alwaysShow ||
        (!this.colSpec.readOnly && !this.colSpec.popOut && this.hasFocus)
      );
    },
    formattedVal() {
      if (!this.colSpec.formatter) return this.td;
      var v = this.td;
      try {
        v = JSON.parse(JSON.stringify(this.td));
      } catch (e) {}
      return this.colSpec.formatter(v);
    },
    hasFocus() {
      return !!(
        this.focusedCell &&
        this.focusedCell.row == this.row &&
        this.focusedCell.col == this.col
      );
    },
  },
  watch: {
    hasFocus(n,o) {
      if (this.hasFocus && this.colSpec.type == "string") {
        return;
        this.$nextTick((_) => {
          this.$refs.span.focus();
          document.execCommand("selectAll", false, null);
          document.getSelection().collapseToEnd();
        });
      }
    },
  },
  mounted() {
    // console.log(`item mounted ${this.row}:${this.col}`);
  },
};
</script>
