<template>
  <span
    contenteditable="true"
    @blur="(e) => handleStringInput(e)"
    v-html="value"
    ref="stringInput"
  ></span>
</template>
<script>
export default {
  name: "ExcelEditorString",
  props: ["value"],
  methods: {
    handleStringInput(v, e) {
      console.log("handleStringInput");

      var txt = this.$refs.stringInput.innerText.replace(
        /^[\s\n]+|[\s\n]+$/g,
        ""
      );
      this.$emit("input", txt);
    },
  },
};
</script>
