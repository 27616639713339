<template>
  <div
    :class="[
      'infoBubble',
      info.pos ? info.pos : 'top',
      info.color ? info.color : '',
    ]"
    :style="infoStyle"
  >
    <div>
      <slot
        v-for="v in slotNames"
        :name="v"
        :row="info.row"
        :col="info.col"
        :id="id"
      ></slot>
    </div>
    <hr class="mask" @click="$emit('close')" />
  </div>
</template>

<script>
export default {
  name: "infoBubble",
  props: {
    colSpec: { type: Object },
    value: null,
    slotNames: Array,
    info: Object,
    focusedCell: Object,
    row: Number,
    col: String,
    selected: Boolean,
  },
  data() {
    return {
      show: false,
      id: this.uuid(),
    };
  },
  methods: {
    uuid(a) {
      return a
        ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
        : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this.uuid);
    },
  },
  computed: {
    infoStyle() {
      return { top: `${this.info.y}px`, left: `${this.info.x}px` };
    },
  },
};
</script>
<style lang="scss">
$bubbleBorder: 2px;
$bubbleLeg: 10px;
.infoBubble {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 100px;
  left: 200px;
  transform-origin: 50% 100%;
  & > div {
    z-index: 5001;
  }
  & > hr {
    margin: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.15);
    width: 100vw;
    height: 100vh;
    z-index: 5000;
    top: 0;
    left: 0;
  }
  &.left > div {
    bottom: 50%;
    transform: translateY(50%);
    left: unset;
    right: $bubbleBorder + $bubbleLeg;
    &:before {
      border-right: unset;
      border-left: ($bubbleLeg + $bubbleBorder) solid lightgray;
      border-top: calc(($bubbleLeg + $bubbleBorder) / 1.4) solid transparent;
      border-bottom: calc(($bubbleLeg + $bubbleBorder) / 1.4) solid transparent;
      bottom: calc(50% - ($bubbleLeg + $bubbleBorder) / 1.4);
      left: unset;
      right: -($bubbleLeg + $bubbleBorder + $bubbleBorder);
    }
    &:after {
      border-right: unset;
      border-left: $bubbleLeg solid #e9ecef;
      border-top: calc($bubbleLeg / 1.4) solid transparent;
      border-bottom: calc($bubbleLeg / 1.4) solid transparent;
      right: -$bubbleLeg;
      left: unset;
      bottom: calc(50% - calc($bubbleLeg / 1.4));
    }
  }
  &.bottom-left > div {
    bottom: 100%;
    transform: translateY(100%);
    border-top-right-radius: 0px;
    left: unset;
    right: $bubbleBorder + $bubbleLeg;
    &:before {
      border-right: unset;
      border-left: ($bubbleLeg + $bubbleBorder) solid lightgray;
      border-top: 0;
      border-bottom: calc(($bubbleLeg + $bubbleBorder) / 1.4) solid transparent;
      bottom: calc(100% - ($bubbleLeg) / 1.4 + 0.5px);
      left: unset;
      right: -($bubbleLeg + $bubbleBorder + $bubbleBorder);
    }
    &:after {
      border-right: unset;
      border-left: $bubbleLeg solid #e9ecef;
      border-top: 0;
      border-bottom: calc($bubbleLeg / 1.4) solid transparent;
      right: -$bubbleLeg;
      left: unset;
      bottom: calc(100% - calc($bubbleLeg / 1.4));
    }
  }
  &.right > div {
    bottom: 50%;
    transform: translateY(50%);
    left: $bubbleBorder + $bubbleLeg;
    &:before {
      border-left: unset;
      border-right: ($bubbleLeg + $bubbleBorder) solid lightgray;
      border-top: calc(($bubbleLeg + $bubbleBorder) / 1.4) solid transparent;
      border-bottom: calc(($bubbleLeg + $bubbleBorder) / 1.4) solid transparent;
      bottom: calc(50% - ($bubbleLeg + $bubbleBorder) / 1.4);
      left: -($bubbleLeg + $bubbleBorder + $bubbleBorder);
    }
    &:after {
      border-left: unset;
      border-right: $bubbleLeg solid #e9ecef;
      border-top: calc($bubbleLeg / 1.4) solid transparent;
      border-bottom: calc($bubbleLeg / 1.4) solid transparent;
      left: -$bubbleLeg;
      bottom: calc(50% - calc($bubbleLeg / 1.4));
    }
  }
  &.bottom-right > div {
    bottom: 100%;
    transform: translateY(100%);
    border-top-left-radius: 0px;
    left: $bubbleBorder + $bubbleLeg;
    &:before {
      border-left: unset;
      border-right: ($bubbleLeg + $bubbleBorder) solid lightgray;
      border-top: 0;
      border-bottom: calc(($bubbleLeg + $bubbleBorder) / 1.4) solid transparent;
      bottom: calc(100% - ($bubbleLeg) / 1.4 + 0.5px);
      left: -($bubbleLeg + $bubbleBorder + $bubbleBorder);
    }
    &:after {
      border-left: unset;
      border-right: $bubbleLeg solid #e9ecef;
      border-top: 0;
      border-bottom: calc($bubbleLeg / 1.4) solid transparent;
      left: -$bubbleLeg;
      bottom: calc(100% - calc($bubbleLeg / 1.4));
    }
  }
  &.bottom > div {
    bottom: unset;
    top: $bubbleBorder + $bubbleLeg;
    &:before {
      border-top: unset;
      border-bottom: ($bubbleLeg + $bubbleBorder) solid lightgray;
      border-left: calc(($bubbleLeg + $bubbleBorder) / 1.4) solid transparent;
      border-right: calc(($bubbleLeg + $bubbleBorder) / 1.4) solid transparent;
      bottom: unset;
      top: -($bubbleLeg + $bubbleBorder + $bubbleBorder);
    }
    &:after {
      border-top: unset;
      border-bottom: $bubbleLeg solid #e9ecef;
      border-left: calc($bubbleLeg / 1.4) solid transparent;
      border-right: calc($bubbleLeg / 1.4) solid transparent;
      bottom: unset;
      top: -$bubbleLeg;
    }
  }
  & > div {
    // opacity: 0.7;
    position: absolute;
    bottom: $bubbleBorder + $bubbleLeg;
    left: 50%;
    white-space: nowrap;
    transform: translateX(-50%);
    background: #e9ecef;
    box-sizing: border-box;
    padding: 10px 10px;
    border: $bubbleBorder solid lightgray;
    // box-shadow: 0px 0px 15px -5px;
    border-radius: 25px;
    &:before {
      content: "";
      border-top: ($bubbleLeg + $bubbleBorder) solid lightgray;
      border-left: calc(($bubbleLeg + $bubbleBorder) / 1.4) solid transparent;
      border-right: calc(($bubbleLeg + $bubbleBorder) / 1.4) solid transparent;
      bottom: -($bubbleLeg + $bubbleBorder + $bubbleBorder);
      left: calc(50% - ($bubbleLeg + $bubbleBorder) / 1.4);
      position: absolute;
    }
    &:after {
      content: "";
      border-top: $bubbleLeg solid #e9ecef;
      border-left: calc($bubbleLeg / 1.4) solid transparent;
      border-right: calc($bubbleLeg / 1.4) solid transparent;
      bottom: -$bubbleLeg;
      left: calc(50% - calc($bubbleLeg / 1.4));
      position: absolute;
    }
    span {
      white-space: nowrap;
      color: white;
      font-size: 40px;
      display: block;
      width: 100%;
      text-align: center;
      &:nth-of-type(n + 2) {
        border-top: 2px solid white;
      }
    }
  }
}
</style>
